"use client";
import { type ReactNode } from "react";
import { NextUIProvider } from "@nextui-org/system";
import { useRouter } from "next/navigation";

export function Providers(props: AppProviderProps) {
  const router = useRouter();
  const { children, className } = props;

  return (
    <NextUIProvider navigate={router.push} {...props}>
      {children}
    </NextUIProvider>
  );
}

interface AppProviderProps {
  children: ReactNode;
  className?: string;
}
